:root {
  --medium-aqua: #44d7b6;
  --amber: #f2b90f;
  --dark-blue: #fafafa;
  --medium-blue: #3e5467;
  --light-blue: #8394a3;
  --background-gray: #f7f9fa;
  --border-gray: #929899;
  --font-one: #ffffff;
  --font-black: #000000;
  --deep-blue: #185487;
  --font-gray: #cbd2d6;
  --theme-green: #00ae42;
  --dark-green: #1d8649;
  --revamp-participantList: #005487;
  --revamp-coachSchedulerCard: #2f7dad;
}

body {
  font-family: Roboto;
}
