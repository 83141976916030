@import '../utilities/branding.scss';

.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  .logo {
    display: flex;
    justify-content: center;
    margin-bottom: 80px;
    margin-top: 125px;

    img {
      height: 250px;
    }
  }
  .top {
    display: flex;
    justify-content: center;
    min-height: 727px;
    max-width: 755px;
    width: 100%;
    height: 100%;
    background-color: $card-brand-color;
    margin-bottom: 200px;
  }
  .top > div {
    max-width: 400px;
    width: 100%;
  }
  .bottom {
    height: 200px;
    max-width: 755px;
    width: 100%;
    background-color: var(--background-gray);
  }
  .ant-btn-primary {
    background-color: var(--deep-blue);
    border-color: var(--deep-blue);
  }
  .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
  }
  .ant-form label {
    font-weight: 500;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    color: var(--deep-blue);
  }
  .ant-btn {
    border-radius: 4px;
    padding: 8px 25px;
    height: auto;
  }
  .ant-btn,
  a {
    font-weight: 500;
    font-family: Roboto, sans-serif;
    font-size: 14px;
  }
  .ant-input {
    height: 60px;
  }
  a {
    color: var(--deep-blue);
    text-decoration: underline;
  }
}
