@import '../../utilities/branding.scss';

.roundButton {
  background-color: #96d9ff;
  margin-bottom: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 10px solid #0065a6;
  outline: none;
  transition: all 0.2s cubic-bezier(0, 1.26, 0.8, 1.28);
  &:hover {
    background-color: #96d9ff;
    cursor: pointer;
    border-color: #003557;
    transform: scale(1.2, 1.2);
  }
  &:active {
    border-color: #003557;
    background-color: #fff;
  }
}
.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 51px;
  width: 51px;
  font-size: 18px;
  font-weight: 600;
  background-color: var(--border-gray);
  color: white;
  border-radius: 50%;
}
