@import '../utilities/branding.scss';

.main {
  height: 80px;
  border-bottom: 1px solid var(--border-gray);
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-areas: 'left left center center center center center center center center right1 right2';
}
.left {
  grid-area: left;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 50px;
  }
}
.right1 {
  grid-area: 'right1';
  display: flex;
  align-items: center;
  justify-content: center;
}
.right2 {
  grid-area: 'right2';
  display: flex;
  align-items: center;
  justify-content: center;
}
.center {
  grid-area: center;
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    height: 100%;
    margin: 0 2.5%;
  }
}
.selected {
  .button {
    color: $selectable-dashboard-text;
    font-weight: bold;
  }
}
.button {
  height: 100%;
  color: #8394a3;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 16px;
  border: none;
  background: none;
}
.teammate {
  border-radius: 4px;
  background-color: white;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  border: none;
  padding: 10px 15px;
  outline: none;
}
.teammate:hover {
  cursor: pointer;
}
.plusButton {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #0092f9;
  background: none;
  border: none;
  padding: 5px 0 5px 0;
  span {
    text-decoration-line: underline;
  }
}

.section {
  margin-bottom: 10px;
}

.programButton {
  border-radius: 4px;
  background-color: #0092f9;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  border: none;
  padding: 10px 35px;
  position: absolute;
  right: 0px;
  bottom: 40px;
  margin-right: 43px;
}
.programButton:hover {
  cursor: pointer;
}

.programDelete {
  border-radius: 4px;
  color: #ed3833;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  border: solid 2px #ed3833;
  padding: 10px 35px;
  position: absolute;
  left: 40px;
  bottom: 40px;
  margin-right: 43px;
}
.programDelete:hover {
  cursor: pointer;
}
.groupDelete {
  border-radius: 4px;
  color: #ed3833;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  border: solid 2px #ed3833;
  padding: 10px 35px;
  position: absolute;
  left: 40px;
  bottom: 40px;
  margin-right: 43px;
}
.groupDelete:hover {
  cursor: pointer;
}

.scheduleTypeButton {
  border-radius: 4px;
  color: #3e92f1;
  background-color: #efefef;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  border: none;
  padding: 10px 15px;
  margin-left: auto;
}

.scheduleTypeButtonDisable {
  border-radius: 4px;
  color: #8394a3;
  background-color: #efefef;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  border: none;
  padding: 10px 15px;
  margin-left: auto;
  position: absolute;
  bottom: 22px;
  right: 0;
}

.activeToggle {
  margin-right: 20px;
}

.activeText {
  font-size: 14px;
  font-weight: bold;
  display: inline;
  position: relative;
  top: 1px;
  right: 7px;
}
