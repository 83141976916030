.main {
  background: #f7f9fa;
  box-shadow: 0 1px 2px 0 rgba(131, 148, 163, 0.37);
}

.reportHeader {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-areas: 'left left center center center center center center center center right right';
  padding-bottom: 15px;
  padding-top: 15px;
}
.left {
  grid-area: left;
  display: flex;
  justify-content: flex-start;
}
.center {
  grid-area: center;
  display: flex;
  justify-content: center;
}
.right {
  grid-area: right;
  display: flex;
  justify-content: flex-end;
}

.reportHeaderLegend {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: 'left-legend center-legend right-legend';
  padding-bottom: 15px;
  p {
    display: inline;
    margin-left: 7px;
    font-weight: 500;
    color: #16263b;
    position: relative;
    top: 1px;
  }
}
.centerLegend {
  grid-area: center-legend;
  display: flex;
  justify-content: space-between;
}
.centerLegendCount {
  grid-area: center-legend;
  display: flex;
  justify-content: space-around;
}

.content {
  margin: 0 2.5%;
}

tr:hover {
  .options {
    visibility: visible;
  }
}

tr {
  .options {
    display: flex;
    justify-content: center;
    button {
      color: black;
    }
  }
}

.scheduleTypeOptions {
  display: flex;
  width: 100%;
  button {
    color: black;
  }
}

.spinner {
  width: 100%;
  padding-top: 40px;
  padding-bottom: 80px;
  text-align: center;
}

.siteLayout .siteLayoutBackground {
  background: #f7f9fa;
  position: relative;
}

.siderCustom {
  height: 100%;
  border-right: 1px solid #efefef;
  border-radius: 0px;
  padding: 0px !important;
}
