@import '../utilities/branding.scss';

.ant-modal {
  .ant-modal-content {
    background: #edeff2;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.198017);
    border-radius: 6px;
  }

  .ant-modal-header {
    background: #edeff2;
    border-radius: 6px;
  }

  .ant-modal-title {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
  }

  .ant-form label {
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.8px;
    color: #000000;
  }

  .ant-form-item {
    margin-bottom: 12px;
  }

  .split {
    display: flex;
    flex-direction: row;
    > div {
      margin: 0 15px 0 15px;
      flex: 1;
    }
  }

  .ant-checkbox-group {
    display: flex;
    .ant-checkbox-group-item {
      &.ant-checkbox-wrapper-checked {
        background: #0092f9;
      }
      .ant-checkbox {
        opacity: 0;
        position: absolute;
      }
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      background: #d8d8d8;
      border: 1px solid #979797;
      box-sizing: border-box;
      border-radius: 3px;
    }
  }

  .work-hours {
    display: flex;
    > div {
      margin: 10px;
    }
  }

  .section {
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    color: #000000;
  }

  .buttons {
    text-align: end;
    button {
      background: #0092f9;
      border-radius: 4px;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;
    }
  }

  .ant-btn-danger:focus,
  .ant-btn-danger:hover {
    background-color: #ff4d4f;
  }
}

.add-text-align {
  position: relative;
  display: inline;
  bottom: 5px;
  left: 7px;
  color: #627585;
}

.ant-switch {
  border: 1px solid #e4e8eb;
  background-color: #f7f9fa;
}

.ant-switch-checked {
  background-color: rgba(0, 0, 0, 0.25);
}

.ant-switch-handle {
  top: 0px;
  left: -2px;
  width: 21px;
  height: 22px;
}

.ant-switch-handle::before {
  background-color: $selectable-dashboard-text;
  bottom: 2px;
  left: 1px;
  box-shadow: 0px 1px 4px #8394a3;
}

.ant-checkbox-inner {
  height: 22px;
  width: 22px;
  background-color: #d8d8d8;
  border: 1px solid #979797;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--dark-green);
  border-color: var(--dark-green);
}

.ant-table-container table > thead > tr > th {
  background: #f7f9fa;
  border-bottom: 1px solid #e4e8eb;
}

.ant-table-tbody > tr > td {
  background: #f7f9fa;
  border-bottom: 1px solid #e4e8eb;
}

.plus-icon-dashboard {
  font-size: 30px;
  color: #627585;
}
