@import '../../utilities/branding.scss';

.coach-start {
  width: 100%;
  max-width: 400px;
  min-height: 323px;
  border-radius: 3px;
  background-color: #ffffff;
  color: $primary-brand-color;
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 0.67px;
  line-height: 20px;
  margin: 10px;
  .coach-start-title {
    font-size: 10px;
    color: #ffffff;
    background-color: var(--revamp-coachSchedulerCard);
    border-radius: 3px 3px 0 0;
    padding: 5px 10px;
    font-weight: 900;
    letter-spacing: 0.67px;
    line-height: 17px;
  }
  .header {
    background-color: var(--revamp-coachSchedulerCard);
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 30px;
    padding: 5px 10px;
  }
  .sub-header {
    color: rgb(119, 135, 149);
    margin: 10px;
  }
  .actions,
  .transport-note-container {
    display: flex;
    justify-content: space-between;

    .left {
      white-space: nowrap;
      margin: 10px;
      grid-area: left;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .right {
      white-space: nowrap;
      margin: 10px;
      grid-area: right;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .center {
      grid-area: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .tab {
      margin: 0 2.5%;
      color: $primary-brand-color;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 16px;
    }
  }
  .drop {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 41px;
    background-color: white;
    color: $primary-brand-color;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 9px;
    border: 1px dashed #e4ebf0;
    border-radius: 3px;
    margin-bottom: 10px;
  }
  .placeholder {
    width: 300px;
  }
  .list {
    display: block;
    margin: 10px;
  }
  .transportation-div {
    width: -webkit-fill-available;
  }
  .transportation-type {
    margin: 10px;
    color: #8394a3;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.93px;
    line-height: 17px;
  }
  .location-skill {
    display: flex;
    justify-content: space-between;
    color: #8394a3;
    font-size: 12px;
    margin: 10px;
    font-weight: 500;
    letter-spacing: 0.93px;
    line-height: 17px;
  }
  .location-skill-item {
    display: flex;
    justify-content: space-between;
    margin: 10px;
    color: var(--deep-blue);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
  }
  .note {
    margin: 10px;
    color: #8394a3;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.93px;
    line-height: 17px;
  }
  .show-note {
    margin: 10px;
    color: var(--deep-blue);
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 20px;
  }
}

.partners-list {
  display: flex;
  flex-direction: column;
  color: var(--deep-blue);
  letter-spacing: 0px;
  font-weight: 400;
  line-height: 1.4;
  padding: 0px 10px 0px 10px;

  .partner-name {
    text-decoration: underline;
    font-size: 14px;
  }
  .partner-info {
    font-size: 12px;
  }
}

.action-header {
  color: #8394a3;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.93px;
  line-height: 17px;
}
