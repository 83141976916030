@import '../../utilities/branding.scss';

.calendar {
  display: flex;
}

.calendar-aside {
  height: 100vh;
  width: 20%;
}

.calendar-container {
  width: 100%;
}

.aside-content {
  height: 100%;
  padding: 20px;
  background-color: var(--revamp-participantList);
  box-shadow: 0 1px 2px 0 $card-brand-color;
  color: var(--font-one);
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0.8px;
  line-height: 20px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    > div {
      margin: 10px 0;
    }
  }
}

.event-wrapper {
  display: flex;
  flex-flow: column wrap;
  height: 100%;
  width: 100%;
  padding: 0.3rem 0.5rem 0.4rem 0.5rem;
  @media only screen and (max-width: 600px) {
    padding: 0.2rem;
    border-left: 2px solid rgba(0, 0, 0, 0.1);
  }
}

.day-event-wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 0.3rem 2rem 0.4rem 0.5rem;
  @media only screen and (max-width: 600px) {
    padding: 0.2rem 1em 0.2em 0.2em;
  }
}

.event-header {
  color: inherit;
  margin: 0;
  h3 {
    color: inherit;
    margin: 0;
    line-height: 1em;
    font-size: 1em;
    font-weight: bold;
  }
  h4 {
    color: inherit;
    margin: 0;
  }
  h5 {
    color: inherit;
    margin: 0px 0px 4px 0px;
    font-size: 12px;
    line-height: 16px;
  }
  @media only screen and (max-width: 600px) {
    h3 {
      line-height: 1em;
      font-size: 1em;
    }
    h4 {
      line-height: 12px;
      font-size: 11px;
    }
    h5 {
      font-size: 11px;
      line-height: 12px;
    }
  }
}

.participants-header {
  padding-bottom: 0.5em;
  color: inherit;
  p {
    margin: 0px 0px 4px 0px;
    font-size: 11px;
    line-height: 16px;
    color: inherit;
  }
}

.participants {
  color: inherit;
  margin: 0;
  text-decoration: underline;
  line-height: 12px;
}

.participant-out {
  color: inherit;
  margin: 0;
  text-decoration: line-through;
  line-height: 12px;
}

.pop-header {
  display: flex;
}

.flex {
  display: flex;
  margin: 0.5rem 0rem;
  h4 {
    margin: 0;
    font-weight: bold;
  }
  p {
    margin: 0;
  }
}

.non-flex {
  margin: 0.5rem 0rem;
  h4 {
    margin: 0 0 0.3rem 0;
    font-weight: bold;
  }
  p {
    margin: 0 0 0.2rem 0;
  }
}

.flex-wrap {
  display: flex;
  flex-flow: row wrap;
  margin: 0 0 0.5rem 0;
  p {
    margin: 0;
  }
}

.time-text {
  font-size: 11px;
  margin: 0;
}

.fc-v-event {
  border: 1px solid transparent;
  background-color: inherit;
}

.ant-popover.popover-info {
  @media only screen and (max-width: 600px) {
    width: 80vw;
  }
}

.close-btn {
  border-left: 2px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  border: none;
}

.fc .fc-timegrid-slot {
  height: 2.5em;
}

.location {
  color: inherit;
  padding-bottom: 0.5em;
  h5 {
    color: inherit;
    line-height: 1em;
    margin: 0;
  }
  p {
    margin: 0px 0px 4px 0px;
    font-size: 11px;
    line-height: 16px;
  }
}

.selected {
  .button {
    color: $selectable-color;
  }
}

.coach-cal-header {
  margin: 0em 0em 1em 1em;
  h3 {
    margin: 0;
  }
}

.coach-name {
  color: white;
}

.custom-picker {
  width: 60%;
  cursor: pointer;
}

.picker {
  margin: 3em 0em 0em 1em;
  h4 {
    color: white;
  }
}

.fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
  margin: 1em;
}

.fc-button {
  box-shadow: none !important;
}
.fc-timeGridDay-button.fc-button.fc-button-primary:active {
  background-color: white;
}

.fc-dayGridWeek-button.fc-button.fc-button-primary:active {
  background-color: white;
}

.fc-timeGridDay-button.fc-button.fc-button-primary.fc-button-active {
  margin: 0.2em 1em;
  background-color: white;
  color: $selectable-color;
  border: none;
  outline: none;
}

.fc-dayGridWeek-button.fc-button.fc-button-primary.fc-button-active {
  margin: 0.2em 1em;
  background-color: white;
  color: $selectable-color;
  border: none;
  outline: none;
}

.fc-timeGridDay-button.fc-button.fc-button-primary {
  margin: 0.2em 1em;
  background-color: white;
  color: $primary-brand-color;
  border: none;
  outline: none;
  font-size: 1.2em;
  text-transform: uppercase;
  font-weight: 700;
}

.fc-dayGridWeek-button.fc-button.fc-button-primary {
  margin: 0.2em 1em;
  background-color: white;
  color: $primary-brand-color;
  border: none;
  outline: none;
  font-size: 1.2em;
  text-transform: uppercase;
  font-weight: 700;
}

.fc-timegrid-col-frame {
  background-color: #f7f9fa;
}
.fc-daygrid-body {
  background-color: #f7f9fa;
}

.fc-col-header-cell-cushion {
  color: white;
  height: 2.5em;
  margin-top: 0.7em;
}
.fc-col-header-cell.fc-day {
  background-color: #2f7dad;
}

.fc-prev-button.fc-button.fc-button-primary {
  background-color: white;
  color: black;
  cursor: pointer;
  border: none;
}

.fc-next-button.fc-button.fc-button-primary {
  background-color: white;
  color: black;
  cursor: pointer;
  border: none;
}

.calendar {
  cursor: auto;
}

.fc-daygrid-event-harness {
  margin: 5px;
}

.fc-daygrid-day-events {
  border: none;
}
