@import '../../utilities/branding';

.participant-list {
  height: 100%;
  padding: 20px;
  background-color: var(--revamp-participantList);
  box-shadow: 0 1px 2px 0 $card-brand-color;
  color: var(--font-one);
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0.8px;
  line-height: 20px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    > div {
      margin: 10px 0;
    }
  }
  .card-holder {
    width: 219px;
    margin-bottom: 10px;
  }
  .placeholder {
    width: 100px;
    height: 10px;
  }
  .spacing {
    margin: 10px 0;
  }
  .participant-box-section {
    height: 25%;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .people-list {
    max-height: 400px;
    overflow-y: auto;
    flex: 1;
  }
  .user-search-margin {
    margin-bottom: 10px;
    width: 195px;
  }
  .participants-off {
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .inner-list {
    min-height: 100px;
  }
  .ant-spin-dot-item {
    background-color: white;
  }
}
