@import '../utilities/branding.scss';

.nav {
  height: 80px;
  border-bottom: 1px solid var(--border-gray);
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-areas: 'left left center center center center center center center center right right';
  @media only screen and (max-width: 500px) {
    grid-template-areas: 'center center center center center center center center center center center right';
  }
}
.left {
  grid-area: left;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 75px;
  }
  @media only screen and (max-width: 500px) {
    display: none;
  }
}
.right {
  grid-area: right;
  display: flex;
  align-items: center;
  justify-content: center;
}
.center {
  grid-area: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tab {
  position: relative;
  color: $primary-brand-color;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 1.07px;
  line-height: 17px;
  text-align: center;
  height: 100%;
  align-items: center;
  display: flex;
  margin: 0 2.5%;
  border: None;
  flex-direction: column;
}

.button {
  color: $primary-brand-color;
  background: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 1.07px;
  line-height: 17px;
  text-align: center;
  height: 100%;
  align-items: center;
  display: flex;
  border: None;
  border-top: 11px solid transparent;
}

.selected {
  .button {
    border-top: 11px solid $selectable-color;
    color: var(--medium-blue);
  }
}

.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 51px;
  width: 51px;
  background-color: var(--border-gray);
  border-radius: 50%;
}
