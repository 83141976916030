.coach-dashboard.participant-card {
  height: 120px;
  width: 100%;
  color: var(--medium-blue);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(98, 117, 133, 0.24);
  display: flex;
  align-items: center;
  margin: 5px 0;
  padding: 0;
  .left-container {
    padding: 5px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-around;
  }
  .icons {
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .check-mark {
    color: #6dd400;
  }
  .name {
    padding: 0 10px;
    color: rgb(67, 84, 101);
    flex: 1;
    .name-header {
      margin: 0;
      font-size: 12px;
      color: rgb(134, 148, 161);
      line-height: 22px;
    }
    .header-detail {
      margin: 0;
      color: var(--deep-blue);
      font-size: 12px;
      font-weight: normal;
      letter-spacing: 0;
      line-height: 13px;
    }
  }
}
