@import '../../utilities/branding.scss';

.coach-card-start {
  background-color: #3bc9aa;
  &.start {
    background-color: var(--background-gray);
  }
  &.event {
    background-color: #aeecac;
  }
  &.finish {
    background-color: #00d0b9;
  }
  .left {
    grid-area: left;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .right {
    grid-area: right;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .center {
    grid-area: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tab {
    margin: 0 2.5%;
    color: $primary-brand-color;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 16px;
  }
}
