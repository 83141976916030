.auto-complete {
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 4px 12px 0 rgba(22, 38, 59, 0.28);
  .results {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
  }
  .no-results {
    padding: 10px;
  }
  input {
    margin: 10px;
    box-sizing: border-box;
    height: 40px;
    width: 155px;
    border: 1px solid #8394a3;
    border-radius: 4px;
    background-color: #ffffff;
  }
  .option {
    height: auto;
    padding: 10px;
    color: var(--deep-blue);
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    width: 100%;
    text-align: left;
    &:hover {
      background-color: rgba(212, 231, 250, 0.7);
    }
  }
}
.lifestyle {
  .anticon {
    font-size: 30px;
    color: #627585;
    &.visible {
      color: #ffffff;
      background: #3e5467;
      padding: 5px;
      border-radius: 3px;
    }
  }
  .selected {
    height: 30px;
    width: 100px;
    color: #3e5467;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 30px;
    border-radius: 20.5px;
    background: #e4e8eb;
    justify-content: space-evenly;
    align-items: center;
    display: flex;
    .display-text {
      white-space: nowrap;
      max-width: 70px;
      overflow: hidden;
    }
    .anticon {
      font-size: 12px;
      color: #8394a3;
    }
    .ant-btn-link {
      padding: 0;
    }
    img {
      position: relative;
      bottom: 1px;
    }
  }
}
