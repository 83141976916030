.user-search {
  color: var(--font-one);
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
  display: flex;
  align-items: center;
  margin-left: 10px;
  &.dark {
    color: var(--font-black);
    .ant-btn-link {
      color: var(--font-black);
    }
  }

  .ant-btn-link {
    color: var(--font-one);
  }
}

.participant-count {
  position: absolute;
  left: 0px;
  top: 38px;
  font-size: 11px;
}
