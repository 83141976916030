@import '../../utilities/branding.scss';

.survey-start {
  display: flex;
  align-items: center;
  grid-area: center;
  justify-content: center;
  margin: 10px;

  .survey-list-wrap {
    min-width: 200px;
    font-size: 15px;
    border-radius: 3px 3px 0 0;
    padding: 5px 8px;
    margin-bottom: 20px;
    margin-top: 20px;
    line-height: 30px;
    flex-direction: column;
    display: flex;
    align-items: center;
    grid-area: center;
    justify-content: center;
  }
  .selection-wrap {
    min-width: 200px;
    font-size: 15px;
    border-radius: 3px 3px 0 0;
    padding: 10px 0;
    margin-bottom: 20px;
    margin-top: 20px;
    flex-direction: column;
    display: flex;
    align-items: center;
    grid-area: center;
    justify-content: center;
  }
  .open-survey-button {
    width: 100%;
    color: rgb(52, 53, 53);
    border-radius: 4px;
    height: 50px;
  }
  .radio-size {
    padding: 0, 10px;
  }
}
