@import '../../../utilities/branding.scss';

.main {
  height: 80px;
  border-bottom: 1px solid var(--border-gray);
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-areas: 'left left center center center center center center center center right right';
}
.left {
  grid-area: left;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 50px;
  }
}
.right {
  grid-area: right;
  display: flex;
  align-items: center;
  justify-content: center;
}
.center {
  grid-area: center;
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    height: 100%;
    margin: 0 2.5%;
  }
}
.selected {
  .button {
    color: $selectable-dashboard-text;
  }
}
.button {
  height: 100%;
  color: #8394a3;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  border: none;
  background: none;
}
.teammate {
  border-radius: 4px;
  background-color: white;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  border: none;
  padding: 10px 15px;
  outline: none;
}
.teammate:hover {
  cursor: pointer;
}
.plusButton {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #0092f9;
  background: none;
  border: none;
  padding: 5px 0 5px 0;
  span {
    text-decoration-line: underline;
  }
}

.section {
  margin-bottom: 10px;
}

ul {
  background-color: white;
  padding: 20px !important;
  border-radius: 5px;
}
