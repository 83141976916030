.schedule-type-section .ListPage_content__Mcovw {
  margin: 0px;
}

.schedule-type-section .Directory_scheduleTypeButton__1xoht {
  position: absolute;
  right: 0;
  bottom: 22px;
}

.event-type-section {
  margin: 10px 14px;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
  z-index: 1001;
}

.row-dragging td {
  padding: 16px;
  z-index: 1001;
}

.row-dragging .drag-visible {
  visibility: visible;
  z-index: 1001;
}

.schedule-type-css
  > div
  > div
  > div
  > div
  > div
  > table
  > .ant-table-thead
  > tr
  > th {
  background-color: #eeefef;
}

.schedule-type-css
  > div
  > div
  > div
  > div
  > div
  > table
  > .ant-table-thead
  > tr
  > th:nth-of-type(1) {
  background-color: #e6e6e6;
}

.schedule-type-css
  > div
  > div
  > div
  > div
  > div
  > table
  > .ant-table-tbody
  > tr
  > td:nth-of-type(1) {
  background-color: #f3f3f3;
}

.schedule-type-css
  > div
  > div
  > div
  > div
  > div
  > table
  > .ant-table-tbody
  > tr
  > td:last-child
  > .ListPage_scheduleTypeOptions__1KIJl
  button:last-child {
  margin-left: auto;
}

.ant-picker-time-panel-column {
  width: 75px;
}
