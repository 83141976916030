.title {
  margin-bottom: 9px;
  font-weight: 500;
  font-size: 16px;
}

.header-update {
  font-size: 28px;
  font-weight: 550;
  margin-bottom: 25px;
}

.content-underheader {
  padding: 20px;
}

.ListPage_main__2-pe_ {
  box-shadow: none;
}

.schedule-type-section {
  position: relative;
  padding-bottom: 80px;
}

.transfer-section {
  margin-bottom: 80px;
}

.ant-transfer-list {
  position: relative;
}

.ant-transfer-list::after {
  content: 'Total (';
  position: absolute;
  bottom: -30px;
  left: 0;
  color: rgba(0, 0, 0, 0.4);
}

.ant-transfer-list-header {
  height: 60px;
}

.ant-transfer-list-body-search-wrapper {
  position: absolute;
  flex: none;
  right: 1px;
  top: 1px;
}

.ant-transfer-list-header-title {
  text-align: left;
  padding-left: 4px;
}

.ant-transfer-list-header > span:nth-of-type(1) {
  display: none;
}

.ant-transfer-list-header > span:nth-of-type(2) {
  position: absolute;
  bottom: -30px;
  left: 40px;
  color: rgba(0, 0, 0, 0.4);
}

.ant-transfer-list-header > span:nth-of-type(2)::after {
  content: ')';
}

.ant-transfer-operation {
  margin: 0 55px;
}

.ant-transfer-operation .ant-btn {
  height: 30px;
}

.ant-transfer-operation .ant-btn:first-child {
  margin-bottom: 10px;
}
