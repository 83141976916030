@import '../../utilities/branding';

.coach-card-back {
  h4 {
    color: var(--font-one);
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0;
    padding: 5px 10px;
  }
  .notes {
    padding-bottom: 15px;
    h4 {
      font-weight: normal;
      color: var(--font-gray);
      padding: 10px 0 0 0;
      margin: 0;
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
  }
  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: #00cc52;
  }
  .ant-radio-wrapper {
    color: #ffffff;
    font-size: 14px;
    letter-spacing: 0;
    font-weight: 300;
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: #00cc52;
  }
  .ant-radio-inner {
    background-color: transparent;
  }
  .ant-radio-inner::after {
    background-color: #00cc52;
  }
  .ant-input {
    border-radius: 4px;
  }
  .content {
    padding: 0 15px 15px 15px;
  }
  .cancel {
    color: #e4e8eb;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    border: 1px solid #ffffff;
    border-radius: 3px;
    background-color: var(--revamp-participantList);
  }
  .ok {
    color: #2e3033;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    border-radius: 3px;
    background-color: #5ae070;
    border: none;
  }
  .partners-content {
    width: 95%;
    height: 220px;
    overflow: auto;
  }
  .partner-list {
    list-style-type: none;
    background-color: rgb(0, 84, 135);
    color: #ffffff;
    letter-spacing: 0px;
    font-weight: 300;
    line-height: 1.4;
    padding: 0px 5px 0px 20px !important;

    .name {
      text-decoration: underline;
      font-size: 14px;
    }
    .text {
      font-size: 12px;
    }
  }
}
