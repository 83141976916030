.ant-picker-input > input {
  display: none;
}
.ant-picker.ant-picker-borderless {
  position: relative;
  bottom: 4px;
}

.calendar {
  .date-button {
    background: none;
    border: none;
    cursor: pointer;
  }
  cursor: pointer;
  color: var(--font-black);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
}

.main {
  background: #f7f9fa;
  box-shadow: 0 1px 2px 0 rgba(131, 148, 163, 0.37);
}

.reportHeader {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-areas: 'left left left left left left left left center center right right';
  padding-bottom: 15px;
  padding-top: 15px;
}

.left-section {
  grid-area: left;
  display: flex;
  justify-content: flex-start;
}

.content {
  margin: 0 2.5%;
}
