.transport {
  margin: 10px;
  color: var(--deep-blue);
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 20px;
}
.dropdown {
  background: #ffffff;
  border: 1px solid #cbd2d6;
  box-sizing: border-box;
  border-radius: 3px;
}
