.report-container {
  margin: 0 2.5%;
  .ant-table table {
    border-collapse: collapse;
    text-align: center;
  }
  .ant-table-container table > thead > tr > th {
    border-bottom: 1px solid #8394a3;
    text-align: center;
    color: #3e5467;
  }
  .ant-table-has-fix-right
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr
    > th:first-child {
    padding-left: 20px;
    text-align: left;
  }
  .ant-table-tbody > tr > td {
    background-color: #ffffff;
    border-bottom: 1px solid #cbd2d6;
    border-right: 1px solid #cbd2d6;
    padding: 12px 10px;
  }
  .ant-table-has-fix-right
    > .ant-table-container
    > .ant-table-content
    > table
    > tbody
    > tr
    > td:first-child {
    border-right: 1px solid #8394a3;
    padding: 15px 20px;
    text-align: left;
  }
  .ant-table-has-fix-right
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr
    > th {
    background-color: #ffffff;
  }
}
.report-download {
  color: #2a8ff7;
  border: 1px solid #2a8ff7;
  background-color: white;
  margin-bottom: 20px;
  font-weight: 600;
  height: 40px;
  border-radius: 5px;
}

.report-header {
  margin: 25px 0;
}

.dropdown-label {
  font-weight: bold;
  margin-right: 15px;
  line-height: 34px;
}

.dropdown-select {
  width: 180px;
  height: 34px;
  border: 1px solid #cbd2d6;
  border-radius: 3px;
  .ant-select-arrow > * {
    position: relative;
    bottom: 9px;
    right: 7px;
  }
}

.report-date-text {
  display: inline;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
}

.report-select {
  .ant-picker {
    border: none;
    background: none;
    position: relative;
    bottom: 5px;
  }
  .ant-picker:hover {
    cursor: pointer;
  }
  .ant-picker-input > input {
    display: none;
  }
  .ant-picker-clear {
    left: 30px;
  }
}

.ant-table-cell-fix-right {
  background: #e4e8eb !important;
  font-weight: 600;
  text-align: center;
  color: #3e5467;
}

.ant-table-has-fix-right
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > th:first-child {
  font-weight: 600;
  padding-left: 50px;
}

.ant-table-has-fix-right
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td:first-child {
  padding: 16px 50px;
}
