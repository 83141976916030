.scheduler {
  display: grid;
  grid-template-columns: repeat(14, 1fr);
  grid-template-areas:
    'sub-header sub-header sub-header sub-header sub-header sub-header sub-header sub-header sub-header sub-header sub-header sub-header sub-header sub-header'
    'left left left right right right right right right right right right right right';

  .sub-header {
    grid-area: sub-header;
  }
  .left-side {
    grid-area: left;
    margin: 0 10px;
  }
  .right-side {
    grid-area: right;
    margin: 0 10px;
  }
}
