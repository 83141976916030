@import '../../utilities/branding.scss';

.schedule-header {
  padding: 10px 0;
  display: grid;
  grid-template-columns: repeat(14, 1fr);
  grid-template-areas: 'left left left left center center center center center center right right right right';

  .left {
    grid-area: left;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .right {
    grid-area: right;
    display: flex;
    align-items: center;
    justify-content: center;
    > div {
      margin-left: 5px;
      margin-right: 5px;
    }
    .header-dropdown {
      .ant-select-arrow > * {
        position: relative;
        bottom: 9px;
        right: 7px;
      }
    }
  }
  .subtitle {
    margin-left: 5px;
    margin-right: 5px;
  }
  .center {
    grid-area: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tab {
    margin: 0 2.5%;
    background-color: #ffffff;
    color: $primary-brand-color;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 16px;
    cursor: pointer;
    border: none;
  }

  .selected {
    color: $selectable-dashboard-text;
    font-weight: 900;
  }

  .anticon-calendar {
    svg {
      color: var(--dark-blue);
    }
  }
  .ant-picker-input > input {
    display: none;
  }
  .calendar {
    .date-button {
      background: none;
      border: none;
      cursor: pointer;
    }
    cursor: pointer;
    color: var(--font-black);
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 30px;
  }
  .header-icon {
    font-size: 32px;
    padding-bottom: 4px;
  }
  .ant-picker.ant-picker-borderless {
    position: relative;
    bottom: 4px;
  }
}
