@import '../../utilities/branding.scss';

.participant-action {
  height: 100%;
  width: 100px;
  border-radius: 0 3px 3px 0;
  background-color: #f7f9fa;

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: #f7f9fa;
    border: none;
    color: #16263b;
    font-size: 14px;
    font-weight: 900;
    letter-spacing: 0.8px;
    line-height: 16px;
    .anticon {
      color: $selectable-dashboard-text;
      font-size: 30px;
      margin-bottom: 10px;
    }
    &.complete {
      background-color: #3e5467;
      color: #44d7b6;
    }
  }
  .location {
    background-color: #f7f9fa;
  }
  .check-in {
    background-color: rgb(247, 249, 250);
  }
  .checked-in {
    background-color: rgb(67, 84, 101);
  }
  .finish {
    background-color: rgb(247, 249, 250);
  }
  .finished {
    background-color: rgb(63, 79, 95);
  }
}
