.coach-list {
  padding: 5px;
  background-color: #e4e8eb;
  &.start {
    background-color: #e4e8eb;
  }
  &.event {
    background-color: #aeecac;
  }
  &.finish {
    background-color: #00d0b9;
  }
  .top {
    display: flex;
  }
  .coach-dropdown {
    color: var(--font-black);
    font-size: 15px;
    letter-spacing: 0;
    font-weight: 400;
    width: 150px;
    position: relative;
    bottom: 5px;
    .ant-select-arrow {
      color: var(--font-black);
    }
    .ant-select-arrow > * {
      margin: -10px 0px 0px -30px;
    }
  }
  .coach-title {
    padding: 10px 10px;
    font-size: 26px;
    font-weight: 600;
    color: #000000;
    display: flex;
    justify-content: space-between;
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    max-height: calc(100vh - 200px);
    min-height: 380px;
  }
  .spin-holder {
    width: 100%;
    padding-top: 50px;
    text-align: center;
  }
  .outdated {
    font-size: 16px;
    margin: 5px;
    text-align: center;
    > div {
      margin: 5px;
    }
  }
}
